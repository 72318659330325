/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
let intialState = {
  programsList: [],
  subjectList: [],
  questionList: [],
  practiceList: [],
  isShow: false,
  isAddSubject: false,
  subjectFromProgram: [],
  addSubject: false,
  programId: "",
  isHide: false,
  isPreviewLoading: true,
  isSubjectLoading: true,
  isProgramLoading: true,
  isQuestionLoading: true,
  isTopicLoading: true,
  edit: false,
  subject_id: "",
  failed: false,
  isSchedule: false,
  allQuizList: [],
  questionListForQuiz: [],
  previewData: "",
  documentDataState: false,
  documentData: "",
  resourceList: [],
  isResourceLoading: true,
  percent: 0,
  qTitle: '',
  qExplanation: '',
  choice1: '',
  isViewed: false,
  resourceLoading: true,
  topic_id: '',
  subtopic: '',
  topic: ''
}

export default function ContentReducer(state = intialState, action) {
  let stateCopy = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "Get_Program_List":
      stateCopy.programsList = localStorage.getItem('role') === 'lne_admin' ? action.payload.filter(el => el.type === 'learnearn') : action.payload;
      stateCopy.subjectFromProgram = [];
      stateCopy.isProgramLoading = false;
      stateCopy.isSubjectLoading = true;
      return stateCopy;
    case "Reset_Program_Loading":
      stateCopy.isProgramLoading = true;
      stateCopy.isTopicLoading = true;
      stateCopy.activeBtn1 = "program";
      stateCopy.isViewed = false;
      return stateCopy;
    case 'Get_Subject_List':
      stateCopy.subjectList = action.payload.filter(a => !stateCopy.subjectFromProgram.some(s => s._id === a._id));
      stateCopy.subjectForSelect = action.payload.map(el => [{ value: el._id, label: el.title }][0]);
      stateCopy.isProgramLoading = false;
      return stateCopy;
    case 'Get_Course_List':
      stateCopy.subjectList = stateCopy.subjectList.concat(action.payload);
      stateCopy.subjectForSelect = stateCopy.subjectForSelect.concat(action.payload.map(el => [{ value: el._id, label: el.title }][0]));
      return stateCopy;
    case 'Get_Corporate_List':
      stateCopy.subjectList = stateCopy.subjectList.concat(action.payload);
      stateCopy.subjectForSelect = stateCopy.subjectForSelect.concat(action.payload.map(el => [{ value: el._id, label: el.title }][0]));
      return stateCopy;
    case 'Get_LE_List':
      stateCopy.subjectList = stateCopy.subjectList.concat(action.payload);
      stateCopy.subjectForSelect = stateCopy.subjectForSelect.concat(action.payload.map(el => [{ value: el._id, label: el.title }][0]));
      return stateCopy;
    case "Get_Subject_List_From_Program":
      stateCopy.subjectFromProgram = action.payload.data.length > 0 ? action.payload.data : [];
      stateCopy.subjectFromProgramCopy = action.payload.data.length > 0 ? action.payload.data : [];
      stateCopy.total = action.payload.semester;
      stateCopy.isSubjectLoading = false;
      var len = stateCopy.total;
      var arr = [];
      for (var i = 0; i < len; i++) {
        arr.push({
          title: 'S' + (i + 1),
          subjects: filterResult(i, stateCopy.subjectFromProgram)
        });
      }
      stateCopy.semesters = arr;
      stateCopy.programId = action.payload.id;
      stateCopy.isTopicLoading = true;
      return stateCopy;
    case "Get_Question_List":
      stateCopy.questionList = action.payload;
      stateCopy.isViewed = true;
      stateCopy.subjectFromProgram = [];
      stateCopy.isProgramLoading = false;
      stateCopy.isSubjectLoading = true;
      return stateCopy;
    case 'Get_Clubbed_Question_List':
      stateCopy.clubQuestionList = action.payload.sort((a, b) => a.title.localeCompare(b.title));
      stateCopy.isViewed = false;
      stateCopy.subjectFromProgram = [];
      stateCopy.isProgramLoading = false;
      stateCopy.isSubjectLoading = true;
      stateCopy.isQuestionLoading = false;
      return stateCopy;
    case 'Get_NEXT_Clubbed_Question_List':
      stateCopy.clubQuestionList = stateCopy.clubQuestionList.concat(action.payload.sort((a, b) => a.title.localeCompare(b.title)));
      stateCopy.isViewed = false;
      stateCopy.subjectFromProgram = [];
      stateCopy.isProgramLoading = false;
      stateCopy.isQuestionLoading = false;
      return stateCopy;
    case "Get_Practice_List":
      stateCopy.practiceList = action.payload;
      stateCopy.isProgramLoading = false;
      return stateCopy;
    case "Add_Practice_List":
      stateCopy.practiceList = [...stateCopy.practiceList, action.payload];
      return stateCopy;
    case "Add_QUESTION":
      stateCopy.questionList = [...stateCopy.questionList, action.payload];
      stateCopy.isViewed = false;
      stateCopy.isShow = false;
      return stateCopy;
    case "Get_Resource_List":
      stateCopy.resourceList = action.payload.sort((a, b) => a.index - b.index);
      stateCopy.isResourceLoading = false;
      return stateCopy;
    case "Get_Resource_List_Error":
      stateCopy.resourceList = [];
      stateCopy.isResourceLoading = false;
      return stateCopy;
    case "Reset_Resource_Loader":
      stateCopy.isResourceLoading = true;
      return stateCopy;
    case "Add_Resource":
      stateCopy.resourceList = [...stateCopy.resourceList, action.payload];
      stateCopy.isShow = false;
      return stateCopy;
    case "Update_Resource":
      return stateCopy;
    case "Add_New_Quiz":
      stateCopy.allQuizList = [...stateCopy.allQuizList, action.payload];
      return stateCopy;
    case "Add_Question_Quiz":
      const updatedQuestionList = stateCopy.questionList.map((question) => {
        if (question._id === action.payload._id) {
          return { ...question, checked: true };
        }
        return question;
      });
      let newData = {
        ...stateCopy,
        questionList: [...updatedQuestionList],
        questionListForQuiz: [...stateCopy.questionListForQuiz, { ...action.payload, score: action.payload?.type === 'Subjective' ? '' : 0, duration: 'NA' }],
      };
      return newData;
    case "GET_ASSIGNMENT_LIST":
      stateCopy.assignmentList = action.payload;
      stateCopy.isProgramLoading = false;
      return stateCopy;
    case "GET_NEXT_ASSIGNMENT_LIST":
      stateCopy.assignmentList = stateCopy.assignmentList.concat(action.payload);
      stateCopy.isProgramLoading = false;
      return stateCopy;
    case 'ADD_ASSIGNMENT':
      return stateCopy;
    case 'Change_Assignment_Status':
      stateCopy.assignmentList.forEach(answer => {
        if (answer._id === action.payload.id) {
          answer.active = !action.payload.checked;
        }
      })
      return stateCopy;
    case "Open_Add_Program":
      stateCopy.isShow = true;
      return stateCopy;
    case "Back_To_Program":
      stateCopy.isShow = false;
      return stateCopy;
    case "Add_Program":
      stateCopy.programsList = [...stateCopy.programsList, action.payload];
      stateCopy.isShow = false;
      return stateCopy;
    case "Get_Active_Btn":
      stateCopy.activeBtn1 = "subject";
      return stateCopy;
    case "Open_Add_Subject":
      if (action.payload.sub !== null) {
        stateCopy.editObj = action.payload.sub;
        stateCopy.edit = action.payload.val;
      } else {
        stateCopy.edit = action.payload.val;
      }
      stateCopy.isAddSubject = true;
      return stateCopy;
    case "Close_Add_Subject":
      stateCopy.isAddSubject = false;
      stateCopy.editObj = null;
      stateCopy.edit = false;
      return stateCopy;
    case "Add_Subject":
      stateCopy.subjectList = [...stateCopy.subjectList, action.payload];
      stateCopy.isAddSubject = false;
      return stateCopy;
    case "Add_Subject_Failed":
      stateCopy.failed = true;
      return stateCopy;
    case "Edit_Subject":
      stateCopy.isAddSubject = false;
      return stateCopy;
    case "Edit_Subject_Failed":
      stateCopy.failed = true;
      return stateCopy;
    case "Edit_Program":
      stateCopy.isShow = true;
      stateCopy.addSubject = false;
      return stateCopy;
    case "Edit_Program1":
      stateCopy.isShow = false;
      return stateCopy;
    case "Change_Score":
      stateCopy.questionListForQuiz.forEach(el => {
        if (el._id === action.payload.data._id) {
          el[action.payload.key] = action.payload.val;
        }
      })
      return stateCopy;
    case "Delete_Question_Quiz":
      const dataSource = [...stateCopy.questionListForQuiz];
      const filteredQuestions = dataSource.filter(
        (allQuestions) => allQuestions._id !== action.payload
      );
      return {
        ...stateCopy,
        questionList: [
          ...stateCopy.questionList.map((question) => {
            if (question._id === action.payload) {
              return { ...question, checked: false };
            }
            return question;
          }),
        ],
        questionListForQuiz: filteredQuestions,
      };
    case "Get_Filter_List":
      if (action.payload === "all") {
        stateCopy.subjectFromProgram = stateCopy.subjectFromProgramCopy;
      } else {
        const filterSem = stateCopy.subjectFromProgramCopy.filter((el) => {
          return el.semester == action.payload.split("S")[1];
        });
        stateCopy.subjectFromProgram = filterSem;
      }
      return stateCopy;
    case "Open_Add_To_Program":
      stateCopy.addSubject = true;
      return stateCopy;
    case "Close_Add_To_Program":
      stateCopy.addSubject = false;
      return stateCopy;
    case "Get_Search_Subject":
      stateCopy.subjectList = action.payload;
      return stateCopy;
    case "Drag_Drop":
      // eslint-disable-next-line eqeqeq
      const obj = stateCopy.subjectList.filter(
        (el) => el._id == action.payload.id
      )[0];
      stateCopy.semesters.map((el) => {
        if (el.title === action.payload.title) {
          var existObj = el.subjects.find(({ _id }) => _id == obj._id);

          if (existObj) {
            return el;
          } else {
            obj["semester"] = action.payload.title.split("S")[1];
            stateCopy.subjectList = stateCopy.subjectList.filter(
              (el) => el._id !== action.payload.id
            );
            return el.subjects.push(obj);
          }
        }
      });
      return stateCopy;
    case "Send_Added_Subject":
      stateCopy.addSubject = false;
      return stateCopy;
    case "Remove_Subject":
      stateCopy.semesters = stateCopy.semesters.map((el) => {
        el.subjects = el.subjects.filter(
          (sub) => sub._id !== action.payload._id
        );
        return el;
      });
      stateCopy.subjectList = [...stateCopy.subjectList, action.payload];
      return stateCopy;
    case "Edit_Programs":
      stateCopy.addSubject = true;
      return stateCopy;
    case "Change_Status":
      stateCopy.subjectList.forEach((answer) => {
        if (answer._id == action.payload.id) {
          answer.active = !action.payload.checked;
        }
      });
      return stateCopy;
    case "Change_Subject_Status":
      stateCopy.subjectFromProgram.forEach((answer) => {
        if (answer._id === action.payload.id) {
          answer.active = !action.payload.checked;
        }
      })
      return stateCopy
    case 'Change_Question_Status':
      stateCopy.questionList.forEach(answer => {
        if (answer._id === action.payload.id) {
          answer.active = !action.payload.checked;
        }
      })
      return stateCopy
    case 'Change_Practice_Status':
      stateCopy.practiceList.forEach(answer => {
        if (answer._id === action.payload.id) {
          answer.active = !action.payload.checked;
        }
      })
      return stateCopy
    case 'Get_Topics':
      stateCopy.topicsList = action.payload.data.sort((a, b) => (a.index > b.index) ? 1 : -1);
      stateCopy.subject_id = action.payload.id
      stateCopy.isTopicLoading = false;
      stateCopy.isSubTopicLoading = true;
      return stateCopy;
    case "Get_Topics_List":
      stateCopy.topicsListOfSubject = action.payload.data.sort((a, b) =>
        a.index > b.index ? 1 : -1
      );
      stateCopy.subject_id = action.payload.id;
      return stateCopy;
    case "Add_Topic":
      stateCopy.topicsList = [...stateCopy.topicsList, action.payload.data];
      stateCopy.subjectList.forEach((product) => {
        if (product._id == action.payload.id) {
          product.topics = product.topics + 1;
        }
      });
      return stateCopy;
    case "Change_Topic_Status":
      stateCopy.topicsList.forEach((answer) => {
        if (answer._id == action.payload.id) {
          answer.active = !action.payload.checked;
        }
      });
      return stateCopy;
    case "Reorder_Topics":
      const contents = Array.from(stateCopy.topicsList);
      const [reorderedItem] = contents.splice(action.payload.source.index, 1);
      contents.splice(action.payload.destination.index, 0, reorderedItem);
      contents.forEach((el, i) => {
        if (el.index !== i + 1) {
          el.index = i + 1;
        }
      });
      stateCopy.topicsList = contents;
      return stateCopy;
    case "Set_Date":
      stateCopy.topicsList.forEach((el) => {
        if (el._id === action.payload.id) {
          el.completion = action.payload.date;
        }
      });
      return stateCopy;
    case "Save_Schedule_Date":
      stateCopy.isSchedule = false;
      return stateCopy;
    case "Open_Manage_Schedule":
      stateCopy.isSchedule = true;
      return stateCopy;
    case "Back_To_Topic":
      stateCopy.isSchedule = false;
      return stateCopy;
    case "Get_SubTopics":
      stateCopy.subTopics = action.payload.sort((a, b) => a.index - b.index);
      stateCopy.subTopicsContent = action.payload.map(el => [{ value: el.title, label: el.title }][0]);
      stateCopy.isSubTopicLoading = false;
      return stateCopy;
    case 'Get_Topic_Object':
      stateCopy.subTopicDetail = action.payload.data.filter(el => el._id === action.payload.topicId)[0];
      return stateCopy;
    case "Reorder_Table_Data":
      const x = stateCopy.questionListForQuiz
      const [removed] = x.splice(action.payload.source.index, 1);
      x.splice(action.payload.destination.index, 0, removed);
      setTimeout(() => {
        stateCopy.questionListForQuiz = x
      }, 0)
      stateCopy.isSubTopicLoading = false;
      return stateCopy;
    case 'Reorder_Sub_Topics':
      const subtopic = Array.from(stateCopy.subTopics)
      const [reorderedSubtopic] = subtopic.splice(action.payload.source.index, 1)
      subtopic.splice(action.payload.destination.index, 0, reorderedSubtopic)
      subtopic.forEach((el, i) => {
        if (el.index !== i + 1) {
          el.index = i + 1
        }
      })
      stateCopy.subTopics = subtopic
      return stateCopy;
    case 'Reorder_Sub_Topics_Resources':
      const resources = Array.from(stateCopy.resourceList)
      const [reorderedSubtopicResource] = resources.splice(action.payload.source.index, 1)
      resources.splice(action.payload.destination.index, 0, reorderedSubtopicResource)
      resources.forEach((el, i) => {
        if (el.index !== i + 1) {
          el.index = i + 1
        }
      })
      stateCopy.resourceList = resources
      return stateCopy;
    case 'Add_Sub_Topic':
      stateCopy.subTopics = [...stateCopy.subTopics, action.payload];
      return stateCopy;
    case "Change_SubTopic_Status":
      stateCopy.subTopics.forEach((answer) => {
        if (answer._id == action.payload.id) {
          answer.active = !action.payload.checked;
        }
      });
      return stateCopy;
    case "Change_SubTopic_Resource_Status":
      stateCopy.resourceList.forEach((answer) => {
        if (answer._id == action.payload.id) {
          answer.active = !action.payload.checked;
        }
      });
      return stateCopy;
    case "Hide_Side_Bar":
      stateCopy.isHide = true;
      return stateCopy;
    case "Show_Side_Bar":
      stateCopy.isHide = false;
      return stateCopy;
    case "Delete_All_Question_Quiz":
      stateCopy.questionListForQuiz = [];
      return stateCopy;
    case "Get_Preview":
      stateCopy.isPreviewLoading = true;
      return stateCopy;
    case "Set_Preview":
      stateCopy.isPreviewLoading = false;
      stateCopy.previewData = action.payload;
      return stateCopy;
    case "Get_Documents":
      stateCopy.documentData = action.payload;
      return stateCopy;
    case "Get_Documents_State":
      stateCopy.documentDataState = action.payload;
      return stateCopy;
    case "Null_Documents":
      stateCopy.documentData = action.payload;
      return stateCopy;
    case "Null_Preview":
      stateCopy.previewData = action.payload;
      return stateCopy;
    case "Get_ALL_Quiz_List":
      stateCopy.allQuizList = action.payload;
      return stateCopy;
    case 'Get_Quiz_Question':
      stateCopy.questionListForQuiz = action.payload?.q_ids ? action.payload.questions.map(t1 => ({ ...t1, ...action.payload.q_ids.find(t2 => t2._id === t1._id) })) : action.payload.questions;
      stateCopy.quizType = action.payload.quiz_type;
      return stateCopy;
    case 'Get_Edit_Question':
      stateCopy.editQuestionData = action.payload;
      stateCopy.questionData = action.payload;
      stateCopy.qTitle = action.payload.title;
      stateCopy.qExplanation = action.payload.description;
      return stateCopy;
    case 'Add_Params':
      stateCopy.topic_id = action.payload.topicId;
      stateCopy.topic = action.payload.topic;
      stateCopy.title = action.payload.subtopic;
      localStorage.setItem('topicId', action.payload.topicId)
      localStorage.setItem('title', action.payload.subtopic)
      localStorage.setItem('topic', action.payload.topic)
      return stateCopy;
    case 'Get_Documents_Percent':
      stateCopy.percent = action.payload;
      return stateCopy;
    case 'Edit_Question':
      stateCopy.qTitle = '';
      stateCopy.choice1 = '';
      stateCopy.choice2 = '';
      stateCopy.choice3 = '';
      stateCopy.choice4 = '';
      stateCopy.qExplanation = '';
      stateCopy.isViewed = false;
      return stateCopy;
    case 'Reset_Percentage':
      stateCopy.percent = 0;
      stateCopy.qTitle = '';
      stateCopy.choice1 = '';
      stateCopy.choice2 = '';
      stateCopy.choice3 = '';
      stateCopy.choice4 = '';
      stateCopy.qExplanation = '';
      return stateCopy;
    case 'Set_Title':
      stateCopy.qTitle = action.payload;
      return stateCopy;
    case 'Set_Explanation':
      stateCopy.qExplanation = action.payload;
      return stateCopy;
    case 'Set_Choice1':
      stateCopy.choice1 = action.payload;
      return stateCopy;
    case 'Set_Choice2':
      stateCopy.choice2 = action.payload;
      return stateCopy;
    case 'Set_Choice3':
      stateCopy.choice3 = action.payload;
      return stateCopy;
    case 'Set_Choice4':
      stateCopy.choice4 = action.payload;
      return stateCopy;
    case 'Get_Subject_Topics_List':
      stateCopy.subjectTopicList = action.payload.sort((a, b) => (a.index > b.index) ? 1 : -1).map(el => [{ value: el._id, label: el.title }][0]);
      return stateCopy;
    case 'Get_Resources_List_For_Type':
      stateCopy.resourceListForType = action.payload.data.map(item => ({ ...item, type: action.payload.type, duration: item.duration ? item.duration : null }));
      stateCopy.resourceLoading = false;
      return stateCopy;
    case 'Get_Gallery_Images':
      let uniqueChars = [...new Set(action.payload)];
      stateCopy.images = uniqueChars;
      return stateCopy;
    case 'GET_TRANSLATION':
      stateCopy.languages = action.payload;
      localStorage.setItem('doc_lang', JSON.stringify(action.payload));
      return stateCopy;
    default:
      return stateCopy;
  }
}

const filterResult = (i, subjects) => {
  return subjects
    .map((el) => {
      if (el.semester === i + 1) {
        return el;
      }
    })
    .filter((el1) => el1);
};
