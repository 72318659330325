let intialState = {
    paymentDetails: {},
    orderObj: {},
    profileDetails: null
}

export default function profileReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        // case 'Get_Payment_Details':
        //     stateCopy.paymentDetails = action.payload;
        //     return stateCopy;
        case 'Get_Order_Id':
            stateCopy.orderObj = action.payload;
            return stateCopy;
        case 'Get_Confirm':
            stateCopy.confirm = action.payload;
            return stateCopy;
        case 'Get_Profile':
            stateCopy.profileDetails = action.payload;
            return stateCopy;
        case 'Empty_Profile':
            stateCopy.profileDetails = null;
            return stateCopy;
        default:
            return stateCopy;
    }
}