let intialState = {
    months: [],
    scores: [],
    engagement_score: '',
    subject: [],
    contentCounts: [],
    consumed: []
}

export default function dashboardReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state))
    switch (action.type) {
        case "Get_Engagement_Score":
            stateCopy.months = action.payload.months;
            stateCopy.scores = action.payload.scores;
            stateCopy.engagement_score = action.payload.engagement_score;
            return stateCopy;
        case "Get_Count_Subject":
            stateCopy.subject = action.payload.subjects;
            stateCopy.contentCounts = action.payload.data;
            return stateCopy;
        case "Get_Monthly":
            stateCopy.months1 = action.payload.months;
            stateCopy.consumed = action.payload.data;
            return stateCopy;
        case 'Get_Topic_Matrics':
            stateCopy.topicMatrics = action.payload;
            return stateCopy;
        default:
            return stateCopy;
    }
}