import moment from 'moment'
let intialState = {
    isLoading: true,
    isNote: false,
    programFilter: [],
    subjectFilter: [],
    dateFilter: [{ name: 'Last 3 months', code: 3, value: false }, { name: 'Last 6 months', code: 6, value: false }, { name: 'Last year', code: 12, value: false }],
    fromDate: '',
    toDate: '',
    programSelected: [],
    subjectSelected: []
}

export default function notesReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case "Get_Notes_List":
            stateCopy.notes = action.payload.data;
            stateCopy.notesPerPage = action.payload.per_page;
            stateCopy.notesTotalPage = action.payload.total;
            stateCopy.pageNumber = 1;
            stateCopy.isLoading = false;
            return stateCopy;
        case "Get_Note_Filters":
            stateCopy.programFilter = action.payload.programs;
            stateCopy.subjectFilter = action.payload.subjects;
            return stateCopy;
        case 'Program_Fliter_Click':
            stateCopy.programFilter = stateCopy.programFilter.map(answer => {
                if (answer._id === action.payload.id) return { _id: answer._id, value: !action.payload.value, name: answer.name }
                return answer
            });
            stateCopy.subjectFilter = stateCopy.subjectFilter.map(answer => {
                if (answer._id === action.payload.id) return { _id: answer._id, value: !action.payload.value, name: answer.name }
                return answer
            });
            stateCopy.dateFilter = stateCopy.dateFilter.map(answer => {
                if (answer.code === action.payload.code) return { code: answer.code, value: !action.payload.value, name: answer.name }
                return answer
            });

            var isProgramSelected = stateCopy.programFilter.filter((e) => {
                return e.value === true
            });
            var isSubjectSelected = stateCopy.subjectFilter.filter((e) => {
                return e.value === true
            });
            stateCopy.programSelected = isProgramSelected;
            stateCopy.subjectSelected = isSubjectSelected;
            return stateCopy;
        case 'Subject_Fliter_Click':
            stateCopy.programFilter = stateCopy.programFilter.map(answer => {
                if (answer._id === action.payload.id) return { _id: answer._id, value: !action.payload.value, name: answer.name }
                return answer
            });
            stateCopy.subjectFilter = stateCopy.subjectFilter.map(answer => {
                if (answer._id === action.payload.id) return { _id: answer._id, value: !action.payload.value, name: answer.name }
                return answer
            });
            stateCopy.dateFilter = stateCopy.dateFilter.map(answer => {
                if (answer.code === action.payload.code) return { code: answer.code, value: !action.payload.value, name: answer.name }
                return answer
            });
            var isProgramSelected1 = stateCopy.programFilter.filter((e) => {
                return e.value === true
            });
            var isSubjectSelected1 = stateCopy.subjectFilter.filter((e) => {
                return e.value === true
            });
            stateCopy.programSelected = isProgramSelected1;
            stateCopy.subjectSelected = isSubjectSelected1;
            return stateCopy
        case "Date_Fliter_Click":
            stateCopy.programFilter = stateCopy.programFilter.map(answer => {
                if (answer._id === action.payload.id) return { _id: answer._id, value: !action.payload.value, name: answer.name }
                return answer
            });
            stateCopy.subjectFilter = stateCopy.subjectFilter.map(answer => {
                if (answer._id === action.payload.id) return { _id: answer._id, value: !action.payload.value, name: answer.name }
                return answer
            });
            stateCopy.dateFilter = stateCopy.dateFilter.map(answer => {
                if (answer.code === action.payload.code) return { code: answer.code, value: !action.payload.value, name: answer.name }
                return answer
            });
            stateCopy.dateFilter.forEach(el => {
                if (el.code === action.payload.code) {
                    if (el.value === false) {
                        stateCopy.fromDate = '';
                        stateCopy.toDate = '';
                    }
                    else {
                        var threeMonthsAgo = moment().subtract(action.payload.code, 'months');
                        stateCopy.fromDate = threeMonthsAgo.format('YYYY-MM-DD');
                        stateCopy.toDate = moment().format('YYYY-MM-DD');
                    }
                }
            })

            var isProgramSelected3 = stateCopy.programFilter.filter((e) => {
                return e.value === true
            });
            var isSubjectSelected3 = stateCopy.subjectFilter.filter((e) => {
                return e.value === true
            });

            stateCopy.programSelected = isProgramSelected3;
            stateCopy.subjectSelected = isSubjectSelected3;
            return stateCopy;
        case "Get_Note":
            stateCopy.note = stateCopy.notes.filter(el => el._id === action.payload._id)[0];
            stateCopy.note = { ...stateCopy.note, ...action.payload };
            stateCopy.isNote = true;
            return stateCopy;
        case "Get_Like":
            stateCopy.note.liked = !action.payload.liked;
            return stateCopy;
        case "Clear_filter":
            stateCopy.programSelected = [];
            stateCopy.subjectSelected = [];
            stateCopy.programFilter = stateCopy.programFilter.map(answer => {
                return { _id: answer._id, value: false, name: answer.name }
            });
            stateCopy.subjectFilter = stateCopy.subjectFilter.map(answer => {
                return { _id: answer._id, value: false, name: answer.name }
            });
            stateCopy.dateFilter = stateCopy.dateFilter.map(answer => {
                return { code: answer.code, value: false, name: answer.name }
            });
            return stateCopy;
        case "Save_Notes":
            const notes = stateCopy.notes.map(answer => {
                if (answer._id === action.payload.r_id) return {
                    _id: answer._id,
                    title: answer.title,
                    image: answer.image,
                    url: answer.url,
                    active: answer.active,
                    source: answer.source,
                    type: answer.type,
                    subject: answer.subject,
                    program: answer.program,
                    note: action.payload.note,
                    topic: answer.topic,
                    createdAt: answer.createdAt

                }
                return answer
            })
            stateCopy.notes = notes;
            return stateCopy;
        case "Reset_Popup":
            stateCopy.note = {};
            stateCopy.isNote = false;
            return stateCopy;
        case "Set_Note":
            stateCopy.note.note = action.payload;
            return stateCopy;
        default:
            return stateCopy;
    }
}

