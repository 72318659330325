var temp
const intialState = {
    subject: [],
    bubbles: [],
    resources: [],
    getIndividualResource: {},
    topicDetails: {},
    questionIds: [],
    question: {},
    questions: [],
    filterPrevResources: [],
    isShow: false,
    studentDetails: [],
    isLoading: true,
    isQuizComplete: false,
    remainQuestion: [],
    correctQuestion: [],
    isSubjectLoading: true,
    semLoading: true,
    isBubbleLoading: true,
    isCountLoading: true,
    activeTab: 1,
    semsArr: [],
    program_id: '',
    subjectCode: '',
    note: null,
    qIndex: 0,
    isProgramLoading: true,
    isShareFeedPopup: false,
    podLoading: false,
    isSkillLoading: true,
    showQuiz: false,
    isLoadingResource: true
}

export default function libraryReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'Get_Details':
            stateCopy.studentDetails = action.payload;
            stateCopy.program_id = action.payload.program_id;
            stateCopy.semLoading = false;
            var arr = [];
            var len = action.payload.total_semesters;
            for (var i = 0; i < len; i++) {
                if (action.payload.current_semester === i + 1) {
                    arr.push({
                        title: 'S' + (i + 1),
                        active: true
                    });
                }
                else {
                    arr.push({
                        title: 'S' + (i + 1),
                        active: false
                    });
                }
            }
            stateCopy.sems = arr;
            return stateCopy;
        case "Get_Programs":
            stateCopy.programs = action.payload;
            stateCopy.isProgramLoading = false;
            return stateCopy;
        case "Get_Subjects":
            stateCopy.subject = action.payload;
            stateCopy.isSubjectLoading = false;
            return stateCopy;
        case 'Reset_Semsters':
            stateCopy.semsArr = [];
            return stateCopy;
        case "Get_Semsters":
            stateCopy.program_id = action.payload.id;
            stateCopy.isSubjectLoading = false;
            stateCopy.semsArr = [...stateCopy.semsArr, action.payload.data];
            return stateCopy;
        case 'Reset_Sub':
            stateCopy.subject = [];
            return stateCopy;
        case 'Get_Subject_Sem':
            stateCopy.subject = action.payload.data;
            stateCopy.sems.forEach((el) => {
                if (el.title === action.payload.sem) {
                    el.active = true;
                }
                else {
                    el.active = false;
                }
            });
            return stateCopy;
        case 'Reset_index':
            stateCopy.isBubbleLoading = true;
            stateCopy.isCountLoading = true;
            return stateCopy
        case 'Get_Bubble_Details':
            stateCopy.bubbleDetails = action.payload;
            stateCopy.isCountLoading = false;
            return stateCopy;
        case 'Get_Bubble':
            stateCopy.bubbles = action.payload.data.sort((a, b) => (a.index > b.index) ? 1 : -1);
            stateCopy.subjectCode = action.payload.code;
            const sub = stateCopy.subject.map((el, index) => {
                if (el._id === action.payload.code) return {
                    _id: el._id,
                    code: el.code,
                    title: el.title,
                    image: el.image,
                    semester: el.semester,
                    total: el.total,
                    view: stateCopy.bubbleDetails.viewed
                }
                return el;
            });
            stateCopy.subject = sub;
            stateCopy.resources = [];
            stateCopy.resourcesCopy = [];
            stateCopy.isBubbleLoading = false;
            return stateCopy
        case 'Reset_Details':
            stateCopy.topicDetails = [];
            stateCopy.isCountLoading = true;
            return stateCopy;
        case 'Reset_Count_Loader':
            stateCopy.isCountLoading = true;
            return stateCopy;
        case 'Get_Topics_Details':
            stateCopy.topicDetails = action.payload;
            stateCopy.isCountLoading = false;
            stateCopy.isLoading = true;
            return stateCopy;
        case 'Get_Resources':
            var sortArr = action.payload.sort((a, b) => { return a.sub_topic_index - b.sub_topic_index });
            var topics = [...new Set(sortArr.map(el => el.topic))].map((topic, sub_topic_index) => ({ topic, sub_topic_index, complete: false }))
            const content = topics.map(data => { return action.payload.sort((a, b) => (a.index > b.index) ? 1 : -1).filter(el => { return data.topic === el.topic }) });
            stateCopy.topics = getComplete(topics, content);
            stateCopy.resourceIndex = [].concat.apply([], content);
            stateCopy.resourcesCopy = action.payload;
            stateCopy.resources = content;
            stateCopy.isLoading = false;
            return stateCopy;
        case 'Reset_Resource_Loading':
            stateCopy.isLoadingResource = true;
            stateCopy.getIndividualResource = {};
            return stateCopy;
        case "Get_Individual_Resource":
            stateCopy.getIndividualResource = action.payload;
            var found1 = stateCopy.filterPrevResources.find(o => o._id === action.payload._id);
            if (found1 === undefined) {
                stateCopy.filterPrevResources = [...stateCopy.filterPrevResources, stateCopy.getIndividualResource];
            }
            stateCopy.isShow = true;
            if (action.payload.type === 'Quiz') {
                stateCopy.questions = action.payload.questions;
                stateCopy.qIndex = stateCopy.questions.findIndex(el => { return el.answered === null });
            }
            stateCopy.showQuiz = false;
            stateCopy.isLoadingResource = false;
            return stateCopy;
        case "Get_Notes":
            if (action.payload.data !== null) {
                stateCopy.note = action.payload.data.note;
            }
            else {
                stateCopy.note = null;
            }
            return stateCopy;

        case 'Analytic_capture':
            const analytics = stateCopy.resourcesCopy.map(answer => {
                if (answer._id === action.payload.r_id) return {
                    _id: answer._id,
                    title: answer.title,
                    source: answer.source,
                    type: answer.type,
                    skill: answer.skill,
                    liked: answer.liked,
                    topic: answer.topic,
                    image: answer.image,
                    index: answer.index,
                    duration: answer.duration,
                    viewed: answer.viewed ? true : action.payload.viewed,
                    recommended: answer.recommended
                }
                return answer
            })
            stateCopy.resourcesCopy = analytics;
            const content1 = stateCopy.topics.map(data => { return stateCopy.resourcesCopy.sort((a, b) => (a.index > b.index) ? 1 : -1).filter(el => { return data.topic === el.topic }) });
            stateCopy.topics = getComplete(stateCopy.topics, content1);
            stateCopy.resources = content1;
            return stateCopy;
        case "Get_Previous_Index":
            stateCopy.getIndividualResource = stateCopy.filterPrevResources.filter(el => el.id === action.payload._id.toString())[0];
            stateCopy.showQuiz = false;
            return stateCopy;
        case 'Get_Questions':
            stateCopy.question = stateCopy.questions.filter(el => el._id === action.payload._id)[0];
            stateCopy.remainQuestion = [];
            stateCopy.showQuiz = true;
            return stateCopy;
        case 'Get_Previous':
            stateCopy.question = stateCopy.questions.filter(el => el._id === action.payload._id)[0];
            return stateCopy;
        case "Get_Answer":
            stateCopy.question.correct = action.payload.data.correct;
            stateCopy.question.correct_id = action.payload.data.correct_id;
            stateCopy.question.answered = action.payload.aid;
            const answersns = stateCopy.questions.map(answer => {
                if (answer._id === action.payload.qid) return {
                    _id: answer._id,
                    title: answer.title,
                    description: answer.description,
                    type: answer.type,
                    skill: answer.skill,
                    liked: answer.liked,
                    answers: answer.answers,
                    correct_id: action.payload.data.correct_id,
                    correct: action.payload.data.correct,
                    answered: action.payload.aid
                }
                return answer
            })
            stateCopy.questions = answersns;
            return stateCopy;
        case "Quiz_Submit":
            stateCopy.isQuizComplete = true;
            stateCopy.questions.forEach((v, i) => {
                if (v.answered == null) {
                    stateCopy.remainQuestion = [...stateCopy.remainQuestion, i];
                }
                else {
                    console.log(i);
                }
            });
            // stateCopy.showQuiz=false;
            return stateCopy;
        case "Save_Score":
            stateCopy.isQuizComplete = false;
            const questions1 = stateCopy.questions.map(answer => {
                return {
                    _id: answer._id,
                    title: answer.title,
                    description: answer.description,
                    type: answer.type,
                    skill: answer.skill,
                    answers: answer.answers,
                    correct_id: null,
                    correct: null,
                    answered: null
                }
            })
            stateCopy.questions = questions1;
            stateCopy.showQuiz = false;
            stateCopy.remainQuestion = [];
            // stateCopy.qIndex = 0;
            return stateCopy;
        case "Close_Modal":
            stateCopy.isQuizComplete = false;
            stateCopy.remainQuestion = []
            return stateCopy;
        case "Retake_Quiz":
            const questions = stateCopy.questions.map(answer => {
                return {
                    _id: answer._id,
                    title: answer.title,
                    description: answer.description,
                    type: answer.type,
                    skill: answer.skill,
                    answers: answer.answers,
                    correct_id: null,
                    correct: null,
                    answered: null
                }
            })
            stateCopy.remainQuestion = []
            stateCopy.questions = questions;
            stateCopy.qIndex = 0;
            stateCopy.showQuiz = true;
            return stateCopy;
        case 'Get_Liked':
            stateCopy.getIndividualResource.liked = !action.payload.liked;
            return stateCopy;
        case "reset_index":
            stateCopy.getIndividualResource = {};
            stateCopy.questions = [];
            stateCopy.isShow = false;
            stateCopy.qIndex = stateCopy.questions.findIndex(el => { return el.answered === null });
            stateCopy.showQuiz = false;
            return stateCopy;
        case 'Show_side':
            stateCopy.isShow = false;
            return stateCopy;
        case 'Share_Popup_Open':
            stateCopy.isShareFeedPopup = true;
            stateCopy.shareObj = action.payload;
            return stateCopy;
        case 'Share_Popup_Close':
            stateCopy.isShareFeedPopup = false;
            stateCopy.shareObj = {};
            return stateCopy;
        case 'Share_Done':
            stateCopy.isShareFeedPopup = false;
            stateCopy.shareObj = {};
            return stateCopy;
        case 'Get_Explore_Skills':
            stateCopy.exploreSkills = action.payload;
            return stateCopy;
        case 'Skill_Toggle_Bookmarks':
            if (action.payload.bookmark) {
                stateCopy.exploreSkills.forEach(el => {
                    if (el._id === action.payload.id) {
                        el.bookmark = false;
                    }
                })
            }
            else {
                stateCopy.exploreSkills.forEach(el => {
                    if (el._id === action.payload.id) {
                        el.bookmark = true;
                    }
                })
            }
            return stateCopy;
        case 'Get_Skill_Pods':
            stateCopy.skillPods = action.payload;
            stateCopy.isSkillLoading = false;
            return stateCopy;
        case 'Get_Next_Skill_Pods':
            stateCopy.skillPods = stateCopy.skillPods.concat(action.payload);
            stateCopy.isSkillLoading = false;
            return stateCopy;
        case 'Add_Pod':
            stateCopy.contentCounts = action.payload;
            stateCopy.podContents = action.payload.resources;
            stateCopy.isOpen = false;
            stateCopy.editObj = null;
            stateCopy.edit = false;
            return stateCopy;
        case 'Edit_Pod':
            stateCopy.contentCounts = { ...stateCopy.contentCounts, title: action.payload.title, image: action.payload.image }
            stateCopy.isOpen = false;
            stateCopy.editObj = null;
            stateCopy.edit = false;
            return stateCopy;
        case 'Get_Pod_Resource':
            stateCopy.contentCounts = { ...action.payload.data, id: action.payload.id };
            stateCopy.podContents = action.payload.data.data.sort((a, b) => (a.index > b.index) ? 1 : -1);
            stateCopy.podLoading = false;
            return stateCopy;
        case 'Reorder_Content':
            const contents = Array.from(stateCopy.podContents);
            const [reorderedItem] = contents.splice(action.payload.source.index, 1);
            contents.splice(action.payload.destination.index, 0, reorderedItem);
            contents.forEach((el, i) => {
                if (el.index !== i + 1) {
                    el.index = i + 1;
                }
            });
            stateCopy.podContents = contents;
            return stateCopy;
        case 'Remove_Resource_From_Pod':
            const removed = stateCopy.podContents.filter(el => { return el._id !== action.payload });
            stateCopy.podContents = removed;
            return stateCopy;
        case 'Open_Add_Skill_Pod':
            stateCopy.isOpen = true;
            if (action.payload.item !== null) {
                stateCopy.editObj = action.payload.item;
                stateCopy.edit = action.payload.edit;
            } else {
                stateCopy.edit = action.payload.edit;
            }
            return stateCopy;
        case 'Close_Add_Skill_Pod':
            stateCopy.isOpen = false;
            stateCopy.editObj = null;
            stateCopy.edit = false;
            return stateCopy;
        case 'Reset_Pod_Data':
            stateCopy.podContents = [];
            stateCopy.contentCounts = {};
            stateCopy.podLoading = true;
            return stateCopy;
        default:
            return stateCopy;
    }
}

const getComplete = (topics, content) => {
    for (var i = 0; i < topics.length; i++) {
        var contentIndex = content[i];
        for (var j = 0; j < contentIndex.length; j++) {
            temp = true;
            if (topics[i].topic === contentIndex[j].topic) {
                if (!contentIndex[j].viewed) {
                    temp = false;
                    break;
                }
            }
        }
        topics[i].complete = temp
    }
    return topics
}