import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        backend: {
            // translation  file path
            loadPath: "/i18n/{{ns}}/{{lng}}.json"
        },
        fallbackLng: 'en',
        debug: false, // disabled in production
        // can have multiple namespaces in case you want to divide 
        // a huge translation into smaller places and load them on demand
        ns: ["common"],
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            formatSeparator: ","
        },
        react: {
            wait: true
        }
    });

export default i18n;