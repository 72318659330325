import { convertDate } from '../Components/miscelleneous';

let intialState = {
    openIndividual: false,
    openIndividual1: false,
    attendanceBatchClasspercent: [],
    attendanceBatchStudentPercent: [],
    attendanceBatchClass: [],
    attendanceBatchStudent: [],
    isTraineeLoading: true,
    dataLoading: true,

    dataLoading1: true,
    dataLoading2: false,
    subjectData: [],
    isloading: true,
    sectionData: [],
    studentList: [],
    countLoading: true,
    ishistoryLoading: true,
    isSubtopicLoading: true,
    byBatchExam: [
        {
            "_id": "62c2d1bb2e3bd0637756fba5",
            "title": "Batch one",
            "exams": 88,
            "score": 90
        },
        {
            "_id": "62c2d1d32e3bd0637756fba6",
            "title": "Batch two",
            "exams": 56,
            "score": 80
        },
        {
            "_id": "6311ef2f98206e330d104f12",
            "title": "batch one",
            "exams": 72,
            "score": 70
        },
        {
            "_id": "63172ce1a00d7e5a8535f123",
            "title": "batch 3",
            "exams": 88,
            "score": 60
        },
        {
            "_id": "63172ce1a00d7e5a8535f123",
            "title": "NEC TOT Batch",
            "exams": 90,
            "score": 50
        },
        {
            "_id": "63172ce1a00d7e5a8535f123",
            "title": "batch 4",
            "exams": 75,
            "score": 40
        },
        {
            "_id": "63172ce1a00d7e5a8535f123",
            "title": "batch 5",
            "exams": 75,
            "score": 30
        },
        {
            "_id": "63172ce1a00d7e5a8535f123",
            "title": "batch 5",
            "exams": 85,
            "score": 20
        }
    ],

}

function getSortData(asc, key, arr) {
    if (asc) {
        return arr.sort((a, b) => (a[key] < b[key] ? 1 : -1));
    }
    else {
        return arr.sort((a, b) => (a[key] > b[key] ? 1 : -1));
    }
}
export default function totReportReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'Open_Individual':
            stateCopy.openIndividual = true;
            stateCopy.centerObj = action.payload.el;
            stateCopy.type = action.payload.type;
            return stateCopy;
        case 'Hide_Chapter':
            stateCopy.isShowChapter = false;
            return stateCopy;
        case 'Close_Individual':
            stateCopy.openIndividual = false;
            stateCopy.centerObj = {};
            return stateCopy;
        case 'Reset_Attendance_Data':
            stateCopy.attendanceBatchClasspercent = [];
            stateCopy.attendanceBatchStudentPercent = [];
            stateCopy.attendanceBatchClass = [];
            stateCopy.attendanceBatchStudent = [];
            return stateCopy;
        case 'Get_Attendance_Batch_Graph':
            stateCopy.attendanceBatchGraphData = action.payload;
            return stateCopy;
        case 'Get_Attendance_Batch_List':
            stateCopy.attendanceBatchData = action.payload.map(el => [{ batch: el.batch, program: el.program, students: el.students, fiftyTo89: el.fiftyTo89, ninetyTo100: el.ninetyTo100, _id: el._id }][0]);
            stateCopy.attendanceBatchDataCopy = stateCopy.attendanceBatchData;
            return stateCopy;
        case 'Sort_Attendance_Batch':
            const sortData = getSortData(action.payload.asc, action.payload.key, stateCopy.attendanceBatchData)
            stateCopy.attendanceBatchData = sortData;
            return stateCopy;
        case 'Search_Batch':
            if (action.payload === '') {
                stateCopy.attendanceBatchData = stateCopy.attendanceBatchDataCopy;
            }
            else {
                const filterResult = stateCopy.attendanceBatchDataCopy.filter(el => { return el.batch.toLowerCase().includes(action.payload.toLowerCase()) });
                stateCopy.attendanceBatchData = filterResult;
            }
            return stateCopy;
        case 'Get_Attendance_Class_Graph':
            stateCopy.attendanceBatchClasspercent = [
                {
                    "name": "50-89%",
                    "y": action.payload.fiftyTo89,
                    color: '#3ABEBC'
                },
                {
                    "name": "90-100%",
                    "y": action.payload.ninetyTo100,
                    color: '#6457E8'
                },
            ]
            return stateCopy;
        case 'Get_Attendance_Top_Class_Graph':
            stateCopy.attendanceBatchClass = action.payload;
            return stateCopy;
        case 'Get_Attendance_Class_Data':
            stateCopy.attendanceClassData = action.payload.map(el => [{ class: el.class, students: el.students, attendance: el.attendance, date: convertDate(el.date), time: el.time, faculty: el.faculty, _id: el._id ,batch:el.batch}][0]);
            return stateCopy;
        case 'Sort_Attendance_Classwise':
            let data1 = getSortData(action.payload.asc, action.payload.key, stateCopy.attendanceClassData);
            stateCopy.attendanceClassData = data1;
            return stateCopy;
        case 'Get_Attendance_Student_Data':
            stateCopy.attendanceBatchStudentPercent = [
                {
                    "name": "50-89%",
                    "y": action.payload.pie.fiftyTo89,
                    color: '#3ABEBC'
                },
                {
                    "name": "90-100%",
                    "y": action.payload.pie.ninetyTo100,
                    color: '#6457E8'
                },
            ]
            stateCopy.attendanceBatchStudent = action.payload.bar;
            stateCopy.attendanceStudentData = action.payload.data.map(el => [{ name: el.name, attendance: el.attendance, batch: el.batch, program: el.program, _id: el._id }][0]);
            return stateCopy;
        case 'Sort_Attendance_Studentwise':
            let data2 = getSortData(action.payload.asc, action.payload.key, stateCopy.attendanceStudentData);
            stateCopy.attendanceStudentData = data2;
            return stateCopy;
        case 'Get_Course_Completion_Data':
            stateCopy.courseCompletionData = action.payload.map(el => [{ batch: el.batch, program: el.program, students: el.students, count: el.count, specialization: el.specialization, _id: el._id }][0]);
            stateCopy.courseCompletionDataCopy = stateCopy.courseCompletionData;
            return stateCopy;
        case 'Get_Schedules':
            let Planned = action.payload.reduce(
                (a, b) => a + b.duration,
                0
            );
            let completed = action.payload.filter(el => { return el.complete }).reduce(
                (a, b) => a + b.duration,
                0
            );
            stateCopy.scheduleDetails = {
                hours_planned: Planned,
                hours_completed: completed,
            }
            stateCopy.schedules = action.payload;
            stateCopy.scheduleLoading = false;
            return stateCopy;

        case 'Reset_Schedules':
            stateCopy.scheduleLoading = true;
            return stateCopy;

        // Exams
        case 'Get_Batch_Wise_Exam_Data':
            stateCopy.examByBatchData = action.payload.map(el => [{ batch: el.batch, program: el.program, students: el.students, exams: el.exams, avg_score: el.avg_score, _id: el._id }][0]);
            stateCopy.examByBatchGraph = action.payload.splice(0, 10).sort((a, b) => b.avg_score - a.avg_score);
            return stateCopy;
        case 'Sort_Exam_BY_Batch':
            let data3 = getSortData(action.payload.asc, action.payload.key, stateCopy.examByBatchData);
            stateCopy.examByBatchData = data3;
            return stateCopy;
        case 'Get_Trainee_Wise_Exam_Data':
            stateCopy.examByTraineeData = action.payload.map(el => [{ name: el.name, program: el.program, num_of_exams: el.num_of_exams, completion: el.completion, top_score: el.top_score, avg_score: el.avg_score, _id: el._id }][0]);
            stateCopy.examByTraineeGraph = action.payload.splice(0, 10).sort((a, b) => b.completion - a.completion);
            return stateCopy;
        case 'Sort_Exam_BY_Trainee':
            let data4 = getSortData(action.payload.asc, action.payload.key, stateCopy.examByTraineeData);
            stateCopy.examByTraineeData = data4;
            return stateCopy;
        //Trainee
        case 'Trainee_Login_Trends':
            stateCopy.dates = ["Feb 13 2022", "Feb 14 2022", "Feb 15 2022", "Feb 16 2022", "Feb 17 2022", "Feb 18 2022", "Feb 19 2022", "Feb 20 2022", "Feb 21 2022", "Mar 05 2022"];
            stateCopy.num_of_logins = [0, 1, 1, 0, 1, 1, 0, 0, 0, 0];
            return stateCopy;
        case 'Trainee_Score_Trends':
            stateCopy.dates = ["Feb 13 2022", "Feb 14 2022", "Feb 15 2022", "Feb 16 2022", "Feb 17 2022", "Feb 18 2022", "Feb 19 2022", "Feb 20 2022", "Feb 21 2022", "Mar 05 2022"];
            stateCopy.score = [0, 0, 5, 1, 0, 1, 1, 0, 8, 0];
            return stateCopy;
        case 'Reset_Trainee_Loading':
            stateCopy.isTraineeLoading = true;
            return stateCopy;
        case 'Trainee_List':
            stateCopy.traineeList = stateCopy.traineList.map(el => [{ name: el.name, program: el.program, section: el.section, engagement_score: el.engagement_score, monthly_engagement_score: el.monthly_engagement_score, monthly_no_of_logins: el.monthly_no_of_logins, lastLogin: el.lastLogin, _id: el._id, email: el.email }][0]);
            stateCopy.total_page = 1;
            stateCopy.isTraineeLoading = false;
            return stateCopy;



        case 'Get_Scores_History':
            stateCopy.scoreHistory = action.payload;
            stateCopy.ishistoryLoading = false;
            return stateCopy;
        case 'Get_Next_Scores_History':
            stateCopy.scoreHistory = stateCopy.scoreHistory.concat(action.payload);
            stateCopy.ishistoryLoading = false;
            return stateCopy;
        case 'Reset_Student_Details':
            stateCopy.scoreDetails = '';
            stateCopy.months = [];
            stateCopy.contents = [];
            stateCopy.subjects = [];
            stateCopy.subjectContent = [];
            stateCopy.subject = [];
            stateCopy.complete = [];
            stateCopy.dataLoading = true;
            stateCopy.dataLoading1 = true;
            stateCopy.dataLoading2 = true;
            stateCopy.ishistoryLoading = true;
            stateCopy.scoreHistory = [];
            return stateCopy;
        case 'Student_Report':
            stateCopy.scoreDetails = action.payload.data;
            stateCopy.subjects = action.payload.subjects;
            stateCopy.subjectContent = action.payload.subjectContent;
            var len = action.payload.data.total_semesters;
            var arr = []
            for (var i = 0; i < len; i++) {
                if (action.payload.data.current_semester === i + 1) {
                    arr.push({
                        title: 'S' + (i + 1),
                        active: true,
                        user_id: action.payload.id
                    });
                }
                else {
                    arr.push({
                        title: 'S' + (i + 1),
                        active: false,
                        user_id: action.payload.id
                    });
                }
            }
            stateCopy.sems = arr;
            stateCopy.semsforTest = arr;
            stateCopy.dataLoading = false;
            return stateCopy;
        case 'Student_Report1':
            stateCopy.months = action.payload.month;
            stateCopy.contents = action.payload.contents;
            const arr1 = [];
            action.payload.logins.dates.forEach(function (v, i) {
                var obj = {};
                obj.date = v;
                obj.login = action.payload.logins.num_of_logins[i];
                arr1.push(obj);
            });
            stateCopy.studentTren = arr1;
            stateCopy.dataLoading1 = false;
            return stateCopy;
        case 'Get_Subject_Completion':
            stateCopy.subject = action.payload.subject2.subject;
            stateCopy.subjectComplete = action.payload.subject2.complete;
            stateCopy.subjectCount = action.payload.subject2.count;
            stateCopy.testComplete = action.payload.test1.complete;
            stateCopy.testCount = action.payload.test1.count;
            stateCopy.activeSub = action.payload.subject2.subject.length ? action.payload.subject2.subject[0].title : '';
            stateCopy.activeSubId = action.payload.subject2.subject.length ? action.payload.subject2.subject[0]._id : '';
            stateCopy.testLoading = false;
            stateCopy.dataLoading2 = false;
            return stateCopy;
        case 'Get_Active_Sem':
            stateCopy.subject = action.payload.subject.subject;
            stateCopy.subjectComplete = action.payload.subject.complete;
            stateCopy.subjectCount = action.payload.subject.count;
            stateCopy.testComplete = action.payload.test.complete;
            stateCopy.testCount = action.payload.test.count;
            stateCopy.activeSub = action.payload.subject.subject.length ? action.payload.subject.subject[0].title : '';
            stateCopy.activeSubId = action.payload.subject.subject.length ? action.payload.subject.subject[0]._id : '';
            stateCopy.sems.forEach((el) => {
                if (el.title === action.payload.sem) {
                    el.active = true;
                }
                else {
                    el.active = false;
                }
            });
            stateCopy.testLoading = false;
            return stateCopy;
        case 'Reset_Sems':
            stateCopy.testLoading = true;
            stateCopy.chapterLoading = true;
            return stateCopy;
        case 'Reset_Sem_Subject':
            // stateCopy.testList = [];
            // stateCopy.subjectTopics = ;
            stateCopy.chapterLoading = true;
            return stateCopy;
        case 'Change_Subjects':
            stateCopy.activeSub = action.payload.sub;
            stateCopy.activeSubId = action.payload.subId;
            return stateCopy;
        case 'Get_Active_Contents':
            stateCopy.subjectTopics = action.payload;
            // stateCopy.testList = action.payload.testList;
            stateCopy.chapterLoading = false;
            return stateCopy;
        case 'Get_Active_Subject_Topics':
            stateCopy.subjectTopics = action.payload;
            stateCopy.chapterLoading = false;
            return stateCopy;
        case 'Get_Test_List1':
            stateCopy.testList = action.payload;
            stateCopy.chapterLoading = false;
            return stateCopy;
        case 'Get_Active_Subject_SubTopics':
            stateCopy.subjectTopics.forEach((el, i) => {
                if (i === action.payload.key) {
                    return el.data = action.payload.data
                }
            })
            stateCopy.isSubtopicLoading = false;
            return stateCopy;
        case 'Reset_Subtopics_Data':
            stateCopy.isSubtopicLoading = true;
            return stateCopy;
        case 'Get_Subtopic_Quiz_History':
            stateCopy.quizHistory = action.payload;
            return stateCopy;
        case 'Clear_Quiz_History':
            stateCopy.quizHistory = [];
            return stateCopy;
        case 'Get_Program':
            stateCopy.programs = action.payload.map(el => [{ value: el._id, label: el.program, value1: 'program' }][0]);
            return stateCopy;
        case 'Get_Section_Lists':
            stateCopy.sections = action.payload.map(el => [{ value: el._id, label: el.title, program_id: el.program._id, semester: el.program.semester, value1: 'section' }][0]);
            return stateCopy;

        default:
            return stateCopy;

    }
}