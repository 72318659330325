let intialState = {
    batches: [],
    courses: [],
    isListLoading: true
}

export default function manageScheduleReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'Get_Schedule_List':
            stateCopy.scheduleList = action.payload.sort(function (a, b) {
                var c = new Date(a.date);
                var d = new Date(b.date);
                return d - c;
            });
            stateCopy.isListLoading = false;
            return stateCopy;
        case 'Get_Batches':
            stateCopy.batches = action.payload.map(el => [{ value: el._id, label: el.title, attendance: el.attendance }][0]);
            return stateCopy;
        case 'Get_Batch_Subjects':
            stateCopy.subjects = action.payload.map(el => [{ value: el._id, label: el.title }][0]);
            return stateCopy;
        case 'Get_Program_Subjects':
            stateCopy.programSubjects = action.payload.map(el => [{ value: el._id, label: el.title }][0]);
            return stateCopy;
        case 'Get_Courses_Topics':
            stateCopy.courseTopics = action.payload;
            return stateCopy;
        case 'Set_Duration':
            stateCopy.courseTopics.forEach((el) => {
                if (el._id === action.payload.id) {
                    el.duration = action.payload.val;
                }
            });
            return stateCopy;
        case 'Reset_Planning':
            stateCopy.courseTopics = [];
            stateCopy.batchScheduleList = [];
            stateCopy.subjects = [];
            stateCopy.programSubjects = [];
            return stateCopy;
        case 'Topics_Scheduled':
            return stateCopy;
        case 'Get_Course_Faculties':
            stateCopy.faculties = action.payload.data.map(el => [{ value: el, label: el, }][0]);
            return stateCopy;
        case 'ADD_FACULTY_EXIST_lIST':
            stateCopy.faculties = getUniques([...stateCopy.faculties, action.payload]);
            return stateCopy;
        default:
            return stateCopy;

    }
}


const getUniques = (arr) => {
    const uniqueIds = [];

    const unique = arr.filter(element => {
        const isDuplicate = uniqueIds.includes(element.label);

        if (!isDuplicate) {
            uniqueIds.push(element.label);

            return true;
        }

        return false;
    });
    return unique
}

