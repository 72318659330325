let intialState = {
    jobs: [],
    bookmark: false,
    isjobsLoading: true
}

// const getId = () => {
//     // Math.random should be unique because of its seeding algorithm.
//     // Convert it to base 36 (numbers + letters), and grab the first 9 characters
//     // after the decimal.
//     return '_' + Math.random().toString(36).substr(2, 9);
// };

export default function jobsReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case "Get_Jobs":
            stateCopy.jobs = action.payload;
            stateCopy.isjobsLoading = false
            return stateCopy;
        case 'Reset_Job_Loader':
            stateCopy.isjobsLoading = true;
            return stateCopy;
        case "Bookmark_Job":
            const bookmarked = stateCopy.jobs.map(answer => {
                if (answer._id === action.payload) return {
                    _id: answer._id,
                    title: answer.title,
                    date: answer.date,
                    company: answer.company,
                    description: answer.description,
                    url: answer.url,
                    thumbnail: answer.thumbnail,
                    type: answer.type,
                    location: answer.location,
                    posted_via: answer.posted_via,
                    bookmarked: true
                }
                return answer
            })
            stateCopy.jobs = bookmarked;
            return stateCopy;
        case "Get_Bookmarked":
            stateCopy.jobs = action.payload;
            stateCopy.isjobsLoading = false;
            return stateCopy;
        case "Toggle_Bookmark":
            stateCopy.bookmark = action.payload
            return stateCopy
        default:
            return stateCopy;
    }
}