import { renameProperties } from '../Components/miscelleneous';

let intialState = {
    viewDetails: false,
    isListLoading: true,
    facultyList: [],
    catagories: [{ value: 'Subject Related', label: 'Subject Related' }, { value: 'Exam', label: 'Exam Related' }, { value: 'Assignment', label: 'Assignment Related' }, { value: 'Others', label: 'Others' }],
    attachment: [],
    query: false,
    activeTab: 'faculty',
    totalPage: 1,
    totalFacultyPage: 1
}

export default function connectReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'Get_Faculty_List':
            stateCopy.facultyList = action.payload.sort((a, b) => a.name.localeCompare(b.name));
            stateCopy.selectFacultyOptions = action.payload.map(el => [{ value: el._id, label: el.name, image: el.profile_image, initial: el.initial, color: el.color }][0]).sort((a, b) => a.label.localeCompare(b.label));
            return stateCopy;
        case 'Get_Centers_List':
            stateCopy.centers = action.payload.map(el => renameProperties(el, { _id: 'value', title: 'label' }));
            return stateCopy;
        case 'Get_Subjects_List':
            stateCopy.subjects = action.payload.map(el => renameProperties(el, { _id: 'value', title: 'label' }));
            return stateCopy;
        case 'Get_Subjects_Filter':
            stateCopy.subjectFilter = action.payload.map(el => renameProperties(el, { _id: 'value', title: 'label' }));
            return stateCopy;
        case 'Get_Faculty_Subject':
            stateCopy.facultySubjects = action.payload.map(el => renameProperties(el, { _id: 'value', title: 'label' }));
            return stateCopy;
        case 'Get_Upload_Url':
            stateCopy.attachment = [action.payload];
            return stateCopy;
        case 'Add_Query':
            stateCopy.query = true;
            stateCopy.activeTab = 'conversation';
            stateCopy.attachment = [];
            return stateCopy;
        case 'Get_Questions_List':
            stateCopy.questionList = action.payload.data;
            stateCopy.totalPage = action.payload.total_page;
            stateCopy.isListLoading = false;
            return stateCopy;
        case 'Get_One_Query':
            stateCopy.viewDetails = true;
            stateCopy.conversation = action.payload.data;
            stateCopy.question = action.payload.details;
            return stateCopy;
        case 'Close_Question_Details':
            stateCopy.viewDetails = false;
            stateCopy.question = null;
            stateCopy.conversation = null;
            stateCopy.attachment = [];
            return stateCopy;
        case 'Closed_Conversation':
            stateCopy.viewDetails = false;
            stateCopy.activeTab = 'conversation';
            if (localStorage.getItem('role') === 'student' || localStorage.getItem('role') === 'trainee') {
                stateCopy.questionList.forEach(el => {
                    if (el._id === action.payload) {
                        el.closed = true;
                    }
                })
            }
            else {
                stateCopy.facultyQuestionList.forEach(el => {
                    if (el._id === action.payload) {
                        el.closed = true;
                        el.new = false;
                    }
                })
            }
            stateCopy.attachment = [];
            return stateCopy;
        case 'Reply_Sent':
            stateCopy.attachment = [];
            if (localStorage.getItem('role') === 'student' || localStorage.getItem('role') === 'trainee') {
                stateCopy.questionList.forEach(el => {
                    if (el._id === action.payload._id) {
                        el.new = false;
                    }
                })
            }
            else {
                stateCopy.facultyQuestionList.forEach(el => {
                    if (el._id === action.payload._id) {
                        el.new = false;
                    }
                })
            }
            return stateCopy;
        case 'Refresh_Attachments':
            stateCopy.attachment = [];
            return stateCopy;
        case 'Remove_Attachments':
            stateCopy.attachment = stateCopy.attachment.filter(el => el !== action.payload);
            return stateCopy;
        // Faculty
        case 'Get_Faculty_Query':
            stateCopy.facultyQuestionList = action.payload.data;
            stateCopy.totalFacultyPage = action.payload.total_page;
            stateCopy.isListLoading = false;
            return stateCopy;
        default:
            return stateCopy;
    }
}
