const intialState = {
    isAddCenter: false,
    sectionList: [],
    centerList: [],
    isListLoading: true
};

export default function accountReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'Is_Add_Center':
            stateCopy.isAddCenter = true;
            return stateCopy;
        case 'Edit_Campus':
            stateCopy.successMessage = true;
            stateCopy.successMessageText = 'Campus details updated';
            return stateCopy;
        case 'Get_Program_List':
            stateCopy.programsCopy = action.payload;
            stateCopy.programs = stateCopy.programsCopy.map(el => [{ value: el._id, label: el.program, type: el.type }][0]).sort((a, b) => a.label.localeCompare(b.label));
            return stateCopy;
        case 'Get_Centers_Only':
            stateCopy.centers = action.payload.map(el => [{ ...el, value: el._id, label: el.title }][0]).sort((a, b) => a.label.localeCompare(b.label));
            return stateCopy;
        case 'Get_Section_List':
            if (action.payload.type === 'batch') {
                stateCopy.batches = action.payload.data;
            } else if (action.payload.type === 'corporate') {
                stateCopy.corporateBatchList = action.payload.data;
            } else if (action.payload.type === 'learnearn') {
                stateCopy.leBatchList = action.payload.data;
            }
            else {
                stateCopy.sectionList = action.payload.data;
            }
            stateCopy.isListLoading = false;
            return stateCopy;
        case 'Get_Center_List':
            stateCopy.centerList = action.payload.type === 'default' ? action.payload.data.filter(el => el.type === 'default') : action.payload.data.filter(el => el.type !== 'default');
            stateCopy.isListLoading = false;
            return stateCopy;
        case 'Get_Specialization':
            stateCopy.specializations = [...action.payload, { _id: null, title: 'Common', program: null }];
            stateCopy.isListLoading = false;
            return stateCopy
        case 'Back_To_Account':
            stateCopy.isAddCenter = false;
            return stateCopy;
        case 'Add_Center':
            stateCopy.isAddCenter = false;
            return stateCopy;
        case 'Edit_Center':
            stateCopy.isAddCenter = false;
            return stateCopy;
        case 'Add_Section':
            stateCopy.isAddCenter = false;
            return stateCopy;
        case 'Edit_Section':
            stateCopy.isAddCenter = false;
            return stateCopy;
        case 'Add_Specialization':
            stateCopy.isAddCenter = false;
            return stateCopy;
        case 'Reset_List':
            stateCopy.isListLoading = true;
            return stateCopy;
        default:
            return stateCopy;
    }
}